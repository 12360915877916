
import Vue, { PropType } from "vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KSelect from "@/components/crud/fields/KSelect.vue";

type ComponentData = {
  internalFilters: Record<string, any>;
};

export default Vue.extend({
  name: "ProductFilter",
  components: { KSelect, KFieldGroup },
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    internalFilters: {
      status: undefined,
    },
  }),
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters;
      },
      immediate: true,
      deep: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
      },
    },
  },
});
