import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface ProductIndexItem {
  id: number;
  productId: number;
  dimProduct: {
    articleNumber?: string;
    productName?: string;
  };
  revenueCode: string;
  stockMinimum: number;
  availableStock: number;
  total: number;
  status: string;
}

export const clientProductIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<ProductIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/product`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
